import {cva} from 'class-variance-authority';

const selectStyleDefaults = [
  'px-6',
  'block w-full',
  'duration-300 ease-out',
  'peer',
  'ring ring-1 ring-inset rounded-[3em]',
  'hover:focus-within:ring-state-focus focus-within:ring-state-focus focus-within:outline-0',
  'appearance-none',
];

export const selectStyles = {
  dark: cva(
    [
      selectStyleDefaults,
      'text-white bg-shade-90 default:ring-shade-60',
      'hover:ring-shade-30',
    ],
    {
      variants: {
        state: {
          success: 'ring-state-success-light',
          warning: 'ring-state-warning-light',
          error: 'ring-state-error-light',
          disabled:
            'disabled:ring-shade-70 disabled:hover:ring-shade-70 disabled:text-shade-50',
        },
        hasLabel: {
          true: 'pb-2 pt-6',
          false: 'pb-4 pt-4',
        },
      },
    },
  ),
  light: cva(
    [
      selectStyleDefaults,
      'text-black bg-white default:ring-shade-30',
      'hover:ring-shade-50',
    ],
    {
      variants: {
        state: {
          success: 'ring-state-success-dark',
          warning: 'ring-state-warning-dark',
          error: 'ring-state-error-dark',
          disabled:
            'disabled:ring-shade-30 disabled:hover:ring-shade-30 disabled:text-shade-40',
        },
        hasLabel: {
          true: 'pb-2 pt-6',
          false: 'pb-4 pt-4',
        },
      },
    },
  ),
};

export const labelStyles = cva(
  [
    'absolute text-base pointer-events-none',
    'duration-300 ease-out',
    '-translate-y-3 scale-75 top-4 z-10 origin-[0] left-6',
  ],
  {
    variants: {
      mode: {
        light: 'text-shade-60',
        dark: 'text-shade-40',
      },
      isDisabled: {
        true: 'opacity-50',
        false: '',
      },
    },
  },
);

export const chevronStyles = cva(
  ['absolute top-1/2 transform -translate-y-1/2 right-4 pointer-events-none'],
  {
    variants: {
      isDisabled: {
        true: 'opacity-50',
        false: '',
      },
    },
  },
);
