import type {InputHTMLAttributes} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Icon from '@/components/base/elements/Icon/Icon';
import Message from '@/components/base/elements/Message/Message';
import {IconEnum} from '@/enums';

import {selectStyles, labelStyles, chevronStyles} from './style';

export type ErrorState =
  | 'success'
  | 'warning'
  | 'error'
  | 'disabled'
  | undefined;

export interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  className?: string;
  id: string;
  label?: string;
  state?: ErrorState;
  message?: string;
  mode?: 'light' | 'dark';
  children?: React.ReactNode;
}

export default function Select({
  className,
  id,
  label,
  state,
  message,
  mode = 'light',
  children,
  ...selectProps
}: SelectProps) {
  const stateTextId = `${id}-${state || 'description'}`;
  const isDisabled = state === 'disabled';

  const messageProps = message && {
    className: 'pl-6 pt-1',
    id: stateTextId,
    mode,
    children: message,
    state,
  };
  const componentName = 'select';

  return (
    <div data-component-name={componentName} data-mode={mode}>
      <div className="relative">
        <select
          key={id}
          {...selectProps}
          aria-describedby={message && stateTextId}
          className={twMerge(
            selectStyles[mode]({state, hasLabel: !!label}),
            className,
          )}
          disabled={isDisabled}
          id={id}
          name={selectProps.name || id}
        >
          {children}
        </select>
        {label && (
          <label className={labelStyles({mode, isDisabled})} htmlFor={id}>
            {label}
          </label>
        )}
        <Icon
          icon={IconEnum.Chevron}
          options={{
            orientation: 'down',
          }}
          className={chevronStyles({isDisabled})}
          size={24}
        />
      </div>
      <Message {...messageProps} />
    </div>
  );
}
